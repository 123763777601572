import styled from "styled-components"
import checkbox from "../../../../static/contact_checkbox.svg"

const HalfStyled = styled.div`
  width: 50%;
  @media (max-width: 880px) {
    order: 2;
    width: 100%;
    ${props => (props.hideMobile ? "display:none !important" : "")}
  }
  .half__content {
    max-width: 590px;
    @media (max-width: 1280px) {
      max-width: 500px;
    }
    @media (max-width: 1080px) {
      max-width: 400px;
    }
    @media (max-width: 880px) {
      max-width: 600px;
      /* padding: 0 40px; */
      margin-left: auto;
      margin-right: auto;
    }

    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &--left {
      padding-right: 4vw;
      margin-left: auto;
      @media (max-width: 880px) {
        padding-right: initial;
        margin-left: auto;
        margin-right: auto;
        padding: 0 40px;
      }
      @media (max-width: 600px) {
        padding: 0 20px;
      }
    }
    &--right {
      padding-left: 4vw;
      @media (max-width: 880px) {
        padding-left: initial;
        margin-left: auto;
        margin-right: auto;
        padding: 0 40px;
      }
      @media (max-width: 600px) {
        padding: 0 20px;
      }
    }
  }
  .half__wrapper {
    background: ${props => props.backgroundColor};
    min-height: 460px;
    height: 100%;
    display: flex;
    padding: 65px 0;
    ${props =>
      props.borderColor ? `border-top: 1px solid ${props.borderColor};` : ""}
    ${props =>
      props.borderColor
        ? ` border-bottom: 1px solid ${props.borderColor};`
        : ""}
    @media (min-width: 1500px) {
      padding: 5.5vw 0;
    }
    @media (max-width: 1280px) {
      height: 100%;
    }
    @media (max-width: 880px) {
      /* border-top: none; */
      /* border-bottom: none; */
      padding: 30px 0;
      min-height: initial;
    }
  }
  ${props =>
    props.backgroundImage
      ? `.half__wrapper--image{
         background: url(${props.backgroundImage});
    background-repeat: no-repeat;
    background-position: ${
      props.position == "left" ? "left center" : "right center"
    };
    background-size: ${props.backgroundSize ? props.backgroundSize : "cover"};
    height: 100%;
    } `
      : ""}
  .halfDescription__description {
    color: ${props => props.descriptionColor};
    font-size: 18px;
  }
  .half__description {
    color: ${props => props.colorText};
    text-align: center;
    font-size: 18px;
  }
  .half__numbers {
    display: flex;
    flex-wrap: wrap;
    &--1 {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .half__numbers__item {
        max-width: 350px;
        width: 100%;
        text-align: center;
        @media (max-width: 880px) {
          padding-bottom: 0px;
        }
      }
    }
    &--2 {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .half__numbers__item {
        padding-bottom: 10px;
        width: 280px;
      }
      .half__numbers__description {
        text-align: center;
      }
    }
    &--4 {
      .half__numbers__description {
        text-align: center;
        height: 2em;
      }
      .half__numbers__item {
        &:nth-last-of-type(1),
        &:nth-last-of-type(2) {
          padding-bottom: 0;
        }
        @media (max-width: 600px) {
          padding-bottom: 50px;
          &:nth-last-of-type(2) {
            padding-bottom: 50px;
          }
        }
      }
    }
    &__number {
      font-size: 74px;
      font-weight: 500;
      margin: 0;
      color: ${props => props.headingColor};
      @media (max-width: 1300px) {
        font-size: 50px;
      }
      @media (max-width: 1200px) {
        font-size: 40px;
      }
    }
    &__description {
      margin: 0;
      font-size: 18px;
      color: ${props => props.descriptionColor};
      @media (max-width: 1080px) {
        text-align: center;
      }
    }
    &__item {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-bottom: 50px;
      @media (max-width: 600px) {
        width: 100%;
      }
    }
  }
  &.halfLongDescription {
    .half__wrapper {
      padding-top: 0;
      border-top: 0;
      border-bottom: 0;
    }
  }
  .half__wrapper__row {
    padding-top: 30px;
    @media (max-height: 750px) {
      padding-top: 10px;
    }
  }
  .half__wrapper__row__title {
    font-size: 24px;
    font-weight: 500;
    margin: 0;
    padding-bottom: 10px;
    @media (max-height: 750px) {
      padding-bottom: 0px;
    }
  }
  .half__wrapper__row__description {
    margin: 0;
    padding-bottom: 30px;
    @media (max-height: 750px) {
      padding-bottom: 15px;
    }
    p {
      margin: 0;
    }
  }
  &.halfIcon {
    @media (max-width: 880px) {
      margin-bottom: 30px;
    }
    @media (max-width: 500px) {
      margin-bottom: 0px;
    }
    ${props =>
      props.backgroundImage
        ? `.half__wrapper--image{
          background: url(${props.backgroundImage});
          background-repeat: no-repeat;
          background-position: center;
          background-size: auto;
          height: 42vw;
          max-height: 690px !important;
            @media (max-width: 1280px){
              background-size: contain;
            }
            @media (max-width: 880px) {
              height: 100%;
              min-height: 500px;
              width:90vw;
              background-size: contain;
            }
            @media (max-width: 500px) {
              min-height: 103vw;
            }
            @media (max-width: 450px) {
              min-height: 240px;
            }
          } `
        : ""};
  }
  &.halfHeading {
    h2 {
      text-align: center;
      color: ${props => props.headingColor};
      margin: 0;
      padding-bottom: 20px;
      font-size: 50px;
      font-weight: 600;
      @media (max-width: 1280px) {
        font-size: 41px;
      }
    }
    .halfHeading__description {
      text-align: center;
      color: ${props =>
        props.descriptionColor ? props.descriptionColor : props.headingColor};
      margin: 0;
      font-size: 18px;
      font-weight: 300;
    }
  }
  .half__career {
    h2 {
      &:after {
        content: unset;
      }
    }
    .thx {
      color: #fff;
      /* font-size:  */
    }
    input[type="file"] {
      visibility: hidden;
      position: absolute;
      width: 20px
    }
    .HalfCarrer__form__row {
      padding-top: 25px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .fileName {
        width: 100%;
        color: #fff;
        font-size: 12px;
        padding: 4px;
        text-align: center;
      }
    }
    .halfDescription__description {
      text-align: center;
    }
    div[class*="ButtonStyles"] {
      margin: 0 10px;
    }
    button {
      transition: 0.3s all;
    }
    input[type="checkbox"] ~ span {
      color: #fff;
      font-size: 12px;
      display: block;
      padding-top: 0px;
      padding-left: 20px;
      line-height: 1.3;
    }
    .error {
      color: red;
      font-size: 12px;
      display: block;
      padding-top: 8px;
      width: 100%;
      text-align: center;
    }
    label {
      color: #fff;
      font-size: 9px;
      line-height: 11px;
      position: relative;
    }
    input[type="checkbox"] {
      visibility: hidden;
      position: absolute;
    }
    input[type="checkbox"] ~ span:first-of-type:before {
      content: "";
      width: 10px;
      height: 10px;
      border: 1px solid #fff;
      display: block;
      position: absolute;
      top: 0px;
      left: 0;
      box-sizing: border-box;
      line-height: 0.5;
    }
    input[type="checkbox"]:checked ~ span:first-of-type:before {
      background: url('${checkbox}'), #000;
      background-position:center;
      background-repeat:no-repeat;
    }
    .HalfCarrer__form__checkbox label {
      padding-top: 15px;
    }
      }
  .halfLogotype__description {
      text-align: center;
      margin-top: -2rem;
      padding-bottom: 1.5rem;
      font-weight: 400;
  }
 
`

export default HalfStyled
