import React from "react"
import HalfStyled from "../styles/half/halfStyles"

const HalfImage = ({
  backgroundImage,
  hideMobile,
  backgroundSize,
  nameClass,
  id,
  position,
}) => {
  return (
    <HalfStyled
      className={nameClass}
      id={id}
      backgroundSize={backgroundSize}
      hideMobile={hideMobile}
      backgroundImage={backgroundImage}
      position={position}
    >
      <div className="half__wrapper--image"></div>
    </HalfStyled>
  )
}

export default HalfImage
