import React, { useState } from "react"
import NewsletterStyled from "../components/styles/NewsletterStyles"
import ButtonStyled from "../components/styles/ButtonStyles"
import myInitObject from "../ultis/variable"

const NewsletterInput = ({
  textButton,
  color,
  placeholder,
  checkbox,
  errorCheckbox,
  errorInput,
}) => {
  const [input, setInput] = useState({})
  const [error, setError] = useState(false)
  const [sent, setSent] = useState(false)

  const buttonClicked = e => {
    e.preventDefault()

    if (!input?.email?.includes("@")) {
      setError(true)
    } else {
      if (!input.newsletter) {
        setError(true)
      } else {
        const formData = new FormData()
        formData.append("formName", "Newsletter Form")
        formData.append("email", input.email)
        formData.append("checkboxOne", input.newsletter)

        if (typeof window !== "undefined") {
          let message = `New message from AudienceNetwork Contact Form (${window.location.href})`
          formData.append("message", message)
        }

        e.target.reset()

        fetch(myInitObject.cockpitUrl + "contactForm/", {
          method: "post",
          body: formData,
        }).then(response => {
          if (response.status === 200) {
            setError(false)
            setSent(true)
          }
        })
      }
    }
  }
  const handleInputChange = e => {
    setError(false)
    setInput({
      ...input,
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }
  return (
    <NewsletterStyled color={color}>
      <div className="newsletter">
        <form
          className="newsletter__row"
          id="newsletter"
          onSubmit={buttonClicked}
        >
          <div className={`newsletter__col newsletter__input `}>
            <input
              className={`${error && !!!input.email ? "error" : null}`}
              type="email"
              placeholder={placeholder}
              name="email"
              onChange={handleInputChange}
            />
            {input.email ? (
              <div className="newsletterCheckBox">
                <input
                  type="checkbox"
                  name="newsletter"
                  id="newsletterCheckBox"
                  onChange={handleInputChange}
                />
                <label htmlFor="newsletterCheckBox">
                  <span dangerouslySetInnerHTML={{ __html: checkbox }}></span>
                  {error && !input?.email?.includes("@") ? (
                    <p className="error">{errorInput}</p>
                  ) : null}
                </label>
              </div>
            ) : null}
            {error && !input.newsletter ? (
              <p className="error">{errorCheckbox}</p>
            ) : null}
            {sent ? (
              <p className="sent">Formularz został poprawnie wysłany</p>
            ) : null}
          </div>
          <div className="newsletter__col newsletter__button">
            <ButtonStyled>
              <button form="newsletter" value="Submit">
                {textButton}
              </button>
            </ButtonStyled>
          </div>
        </form>
      </div>
    </NewsletterStyled>
  )
}

export default NewsletterInput
