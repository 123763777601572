import React from "react"
import HalfStyled from "../styles/half/halfStyles"
import TitleStyled from "../styles/titleStyles"

const HalfDescription = ({
  backgroundColor,
  heading,
  headingColor,
  description,
  descriptionColor,
  position,
  headingPostion,
  showLine,
  nameClass,
  lineColor,
  id,
}) => {
  return (
    <HalfStyled
      className={nameClass}
      id={id}
      backgroundColor={backgroundColor}
      headingColor={headingColor}
      descriptionColor={descriptionColor}
      lineColor={lineColor}
    >
      <div className="half__wrapper">
        <div
          className={`half__content ${
            position === "left" ? "half__content--left" : "half__content--right"
          }`}
        >
          {heading ? (
            <TitleStyled
              textAlign={`${headingPostion ? headingPostion : "left"}`}
              linePosition={`${headingPostion ? headingPostion : "left"}`}
              color={descriptionColor}
              headingColor={headingColor}
              showLine={showLine}
              position={position}
              lineColor={lineColor}
            >
              <h2 dangerouslySetInnerHTML={{ __html: heading }}></h2>
            </TitleStyled>
          ) : (
            ""
          )}
          <div
            className="halfDescription__description"
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
        </div>
      </div>
    </HalfStyled>
  )
}

export default HalfDescription
