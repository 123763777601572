import React from "react"
import HalfStyled from "../styles/half/halfStyles"
import TitleStyled from "../styles/titleStyles"
import ButtonStyled from "../styles/ButtonStyles"
import Link from "gatsby-link"

const HalfButton = ({
  backgroundColor,
  heading,
  headingColor,
  link,
  linkText,
  position,
}) => {
  return (
    <HalfStyled backgroundColor={backgroundColor}>
      <div className="half__wrapper">
        <div
          className={`half__content ${
            position === "left" ? "half__content--left" : "half__content--right"
          }`}
        >
          <TitleStyled
            textAlign="center"
            linePosition="center"
            color={headingColor}
            lineColor={backgroundColor}
          >
            <h2 dangerouslySetInnerHTML={{ __html: heading }}></h2>
          </TitleStyled>
          <div className="HalfButton__button">
            <ButtonStyled dark={false}>
              <Link to={link}>{linkText}</Link>
            </ButtonStyled>
          </div>
        </div>
      </div>
    </HalfStyled>
  )
}

export default HalfButton
