import styled from "styled-components"
import checkbox from "../../../static/contact_checkbox.svg"

const NewsletterStyled = styled.div`
  .newsletter__row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 40px;
    @media (max-width: 880px) {
      flex-direction: column;
    }
  }
  .newsletter__col {
  }
  .newsletter__input {
    max-width: 320px;
    width: 100%;

    input[type="email"] {
      width: 100%;
    }
    input[type="checkbox"] {
      /* width: 100%; */
      visibility: hidden;
      position: absolute;
    }
    .newsletterCheckBox {
      padding-top: 20px;
      padding-left: 20px;
      color: ${props => props.color};
      font-size: 8px;
      position: relative;
    }
  }
  .newsletter__button {
    @media (max-width: 880px) {
      margin-top: 30px;
      text-align: right;
      margin-left: auto;
    }
    @media (max-width: 600px) {
      margin-right: auto;
    }
  }
  button {
    background: transparent;
    color: ${props => props.color};
    cursor: pointer;
  }
  input[type="email"] {
    color: ${props => props.color};
    background: transparent;
    font-size: 16px;
    border: none;
    border-bottom: 1px solid ${props => props.color};
    padding: 15px 30px;
    &.error {
      border-bottom: 1px solid #ff0000;
    }
    &::placeholder {
      color: ${props => props.color};
    }
  }
  input[type="checkbox"] {
    & ~ label {
      line-height: 1.3;
      &:before {
        content: "";
        width: 10px;
        height: 10px;
        border: 1px solid #fff;
        display: block;
        position: absolute;
        top: 22px;
        left: 0;
      }
    }
    &:checked {
      & ~ label {
        &:before {
          background: url('${checkbox}'), #000;
        background-position:center;
        background-repeat:no-repeat;
        }
      }
    }
  }
  .error {
    color: #ff0000;
    font-size: 10px;
  }
  .sent {
    
    font-size: 12px;
  }
`

export default NewsletterStyled
