import React, { useState } from "react"
import { useCountUp } from "react-countup"
import VisibilitySensor from "react-visibility-sensor"

function isFloat(x) {
  return !!(x % 1)
}

const CaseStudyList = ({ number }) => {
  const [isVisible, setVisibility] = useState(false)
  let int, prefix, suffix
  let prefixRegExp = /(^([a-z]\D)+)/g
  let numberRegExp = /[0-9]+.?([0-9]+)?/g
  let suffixRegExp = /(\D*$)/g
  let executed = false
  if (number.match(numberRegExp)) {
    int = parseFloat(number.match(numberRegExp)[0].replace(",", "."))
  }
  if (number.match(prefixRegExp)) {
    prefix = number.match(prefixRegExp)[0]
  }
  if (number.match(suffixRegExp)) {
    suffix = number.match(suffixRegExp)[0]
  }
  const { countUp, start } = useCountUp({
    start: 0,
    end: int,
    duration: 2.5,
    prefix: prefix ? prefix : "",
    suffix: suffix ? suffix : "",
    redraw: false,
    startOnMount: false,
    decimal: ",",
    decimals: isFloat(int) ? 2 : 0,
  })

  const onChange = visiblity => {
    if (!!visiblity) {
      setVisibility(visiblity)
      if (!executed) {
        executed = true
        start()
      }
    }
  }

  return (
    <>
      <VisibilitySensor
        scrollCheck={true}
        partialVisibility
        onChange={onChange}
      >
        <span>{countUp}</span>
      </VisibilitySensor>
    </>
  )
}

export default CaseStudyList
